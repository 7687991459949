// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// biome-ignore lint/style/noNamespaceImport: <explanation>
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"1ebb31fb0b2bdda702ab2c0a06421e4126ab60f6"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/browser";
import { excludeGraphQLFetch } from "apollo-link-sentry";
Sentry.init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  dsn: "https://3442d900fa1ce857bdff7af4ac07fba0@o1264099.ingest.sentry.io/4505719874256896",
  enableTracing: process.env.NEXT_PUBLIC_SENTRY_LOGGING_ENABLED === "true",
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration()],
  attachStacktrace: true,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE ? +process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE : 0.25,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  beforeBreadcrumb: excludeGraphQLFetch,
  sendDefaultPii: false,
  beforeSend(event) {
    if (event.request?.headers) {
      event.request.headers = {
        ...event.request.headers,
        authorization: "",
      };
    }

    return event;
  },
});

//lazy load replay integration
import("@sentry/nextjs").then((lazyLoadedSentry) => {
  Sentry.addIntegration(
    lazyLoadedSentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  );
});
